import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import { mq, spacing } from '../utils/presets'
import SEO from '../components/SEO'
import Text from '../components/Text'

const PageContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
`

const Container = styled.div`
    flex-direction: row;
    justify-content: center;
    text-align: center;
    border: 3px solid;
    border-radius: 10px;
    ${spacing(30)}
    ${spacing(60, 'margin')}
    ${mq.tablet} {
        border: 4px solid;
    }
`

const StyledTitle = styled(Text)`
    ${spacing(30, 'margin-bottom')}
`

export default function NotFoundPage() {
    return (
        <Layout fullPage>
            <SEO title="404 - Page not found" />
            <PageContainer>
                <Container>
                    <StyledTitle type="h1">404</StyledTitle>
                    <Text type="h4">Oh no! Page not found :(</Text>
                </Container>
            </PageContainer>
        </Layout>
    )
}
